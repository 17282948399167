<template>
    <nav class="navbar navbar-light navbar-collapse">
        <div class="container-fluid">
            <div class="navbar-brand">
                <router-link to="/">
                    <h1 class="title">{{ 'Ignė Ale' }}</h1>
                    <h3 class="sub-title">{{ 'rašytoja | vertėja' }}</h3>
                </router-link>
            </div>

            <div class="navbar-text">
                <SocialList />

                <button type="button" class="navbar-toggler" @click.prevent="showOffCanvasMenu()">
                    <icon icon="fas fa-bars" />
                </button>
            </div>
        </div>
        <div class="offcanvas offcanvas-end" :class="showMenu ? 'show' : ''" tabindex="-1" :style="{ visibility: showMenu ? 'visible' : 'hidden' }">
            <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset" @click.prevent="showOffCanvasMenu()"></button>
            </div>
            <div class="offcanvas-body">
                <ul>
                    <li class="mb-3">
                        <router-link @click.native="showOffCanvasMenu()" to="/tekstai">Tekstai</router-link>
                    </li>
                    <li>
                        <span class="text-center">Knygos</span>
                        <ul>
                            <li v-for="book in books" :key="book.slug">
                                <router-link @click.native="showOffCanvasMenu()" :to="'/knyga/' + book.slug">{{ book.title }}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="mb-3">
                        <router-link @click.native="showOffCanvasMenu()" to="/renginiai">Renginiai</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import SocialList from "@/components/SocialList";

export default {
    name: 'Navigation',
    components: {
        SocialList
    },
    props: {
        books: Array,
    },
    data(){
        return {
            showMenu: false
        }
    },
    methods: {
        showOffCanvasMenu() {
            this.showMenu ? this.showMenu = false : this.showMenu = true;
        }
    }
}
</script>

<style scoped>
.title {
    font-family: 'Abril Fatface', 'Palatino Linotype', serif;
    text-align: left;
    font-weight: 400;
    overflow-wrap: break-word;
    font-size: 2em;
}

.sub-title {
    font-size: 0.75em;
}

.navbar {
    background-color: var(--var-bg-color);
}

.navbar-brand a {
    text-decoration: none;
    color: var(--var-primary-color);
}

.offcanvas {
    background: var(--var-bg-color);
}

.offcanvas-body ul {
    list-style-type: none;
}

.navbar-toggler {
    border: none;
    color: #000;
}
</style>
