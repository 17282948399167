<template>
    <section id="about-me" v-if="content">
        <article class="row justify-content-center">
            <div class="col-12">
                <h2 class="title text-center">{{ content.title }}</h2>
            </div>

            <div class="col-10 col-md-5">
                <div class="content" v-html="getContent(content.body.json)"></div>
            </div>
            <div class="col-10 col-md-5 text-center">
                <img :src="content.photo.url" :alt="content.photo.title" />
            </div>
        </article>
    </section>
</template>

<script>

import Page from '../contentful/Page/Page'
import { getContent } from '../utils/utils'

export default {
    name: 'About',
    data() {
        return {
            content: null
        }
    },
    methods: {
        async getAboutMe() {
            this.content = await Page.getBySlug('apie-mane');
        },
        getContent(content) {
            return getContent(content);
        }
    },
    mounted() {
        this.getAboutMe();
    },
}

</script>

<style scoped>
section {
    background-color: var(--var-primary-color);
    align-items: center;
    justify-content: center;
}

article img {
    max-width: 80%;
    max-height: 80%;
    margin-bottom: 2em;
}

.title {
    font-size: 5em;
    margin: 0.75em 0;
}

@media (max-width: 992px) {
    .title {
        font-size: 2em;
        word-break: break-word;
    }
}
</style>
