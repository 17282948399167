import ContentfulApi from "@/contentful/Api";

export default class ContentfulPageContent extends ContentfulApi {
    static async getList() {
        const query = `
            {
                collection:kbAppArticleCollection(where: {system: false}, order: [sys_publishedAt_DESC]) {
                    items {
                        sys {
                            id,
                            publishedAt
                        }
                    title,
                    slug,
                    intro,
                    photo {
                        title,
                        url
                    }
                }
            }
        }`;

        return this.getCollection(query);
    }

    static async getFeatured() {
        const query = `
            {
                collection:kbAppArticleCollection(limit: 1, where: {featured: true}) {
                    items {
                        sys {
                            id
                        }
                    title,
                    body {
                        json
                    },
                    photo {
                        title,
                        url
                    }
                }
            }
        }`;

        return this.getSingle(query);
    }

    static async getBySlug(slug) {
        const query = `
            {
                collection:kbAppArticleCollection(limit: 1, where: {slug: "${slug}"}) {
                    items {
                        sys {
                            id
                        }
                    title,
                    body {
                        json  
                    },
                    photo {
                        title,
                        url
                    }
                }
            }
        }`;

        return this.getSingle(query);
    }
}