import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router/router'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './utils/icons'

const app = createApp(App).use(router)
app.component('icon', FontAwesomeIcon)
app.mount('#app')
