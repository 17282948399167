import Homepage from "@/views/Homepage";
import Book from "@/views/Book";
import Events from "@/views/Events";
import NotFound from '@/views/NotFound'
import Texts from "@/views/Texts";
import Text from "@/views/Text";

export default {
  mode: 'history',
  routes: [
    { path: '/', component: Homepage },
    { path: '/tekstai', component: Texts },
    { path: '/tekstas/:slug', component: Text },
    { path: '/knyga/:slug', component: Book },
    { path: '/renginiai', component: Events },
    { path: '/404', component: NotFound },
    { path: '/:catchAll(.*)', redirect: '/404' }
  ]
}
