<template>
    <section class="content">
        <article class="row justify-content-center">
            <div class="col-10 col-md-5">
                <h2>404 NOT FOUND</h2>
            </div>
        </article>
    </section>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>

<style scoped>
.content {
    margin: 2.5rem 0;
}
</style>
