<template>
    <swiper
        :navigation="true"
        :modules="modules"
        :slidesPerView="1"
        :spaceBetween="30"
        :pagination="{
            clickable: true,
        }"
        :breakpoints="{
            640: {
                slidesPerView: 2,
            },
            900: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        }"
        class="book-swiper">
        <swiper-slide
            data-swiper-autoplay="2000"
            class="text-center"
            v-for="book in books"
            :key="book.slug">
            <div class="book-item">
                <router-link :to="'/knyga/' + book.slug">
                    <img class="book-cover" :src="book.cover.url" :alt="book.cover.title" />
                </router-link>
                <h3>{{ book.title }}</h3>
            </div>
        </swiper-slide>
    </swiper>
</template>

<script>
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';

export default {
    name: 'BookCarousel',
    props: {
        books: Array,
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation],
        };
    },
}

</script>

<style scoped>
.book-cover {
    max-width: 50%;
    padding: 1rem;
}

.book-swiper h3 {
    font-size: 1.25rem;
    margin: 0.25rem 0;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-button-next,
.swiper-button-prev {
    outline: none;
    top: 50%
}
</style>
