<template>
    <div class="wrap">
        <Navigation :books="books" />
        <router-view :key="$route.fullPath" />
        <Footer :books="books" />
    </div>
</template>

<script>

import Navigation from '@/components/Navigation'
import Footer from '@/components/Footer'
import BookCollection from "@/contentful/Book/BookCollection";

export default {
    name: 'igne_ale',
    components: {
        Navigation,
        Footer
    },
    data() {
        return {
            books: null
        }
    },
    methods: {
        async getBooks() {
            this.books = await BookCollection.getList();
        },
    },
    mounted() {
        this.getBooks();
    },
}

</script>

<style>
@import url('~bootstrap/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Montserrat:wght@200&display=swap');

:root {
    --var-bg-color: rgb(185,210,220);
    --var-bg-darken-color: rgb(185, 210, 180);
    --var-primary-color: #ffffff;

    --swiper-navigation-color: var(--var-primary-color);
    --swiper-theme-color: var(--var-primary-color);
}

body {
    font-family: 'Montserrat', Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

section#hero {
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: url('assets/bg.jpeg') center center rgba(13,34,61,0.75);
    background-size: cover;
    background-blend-mode: multiply;
    color: #fff;
}

a {
    color: #ffffff;
    text-decoration: none;
    transition: 0.6s;
}

a:hover {
    color: #005776;
    transition: 0.6s;
}

.book-cover {
    min-width: 200px;
    max-width: 66%;
    aspect-ratio: 0.7;
    border-radius: 0 3% 3% 0/2%;
}
</style>
