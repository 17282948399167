import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import moment from 'moment';

function getContent(content) {
    return documentToHtmlString(content);
}

function formatDate(date) {
    return moment(date).format('YYYY-MM-DD, HH:mm');
}

export {
    getContent,
    formatDate
};
