<template>
    <footer>
        <div class="container">
            <BookCarousel :books="books" />
        </div>
        <div class="container">
            <div class="row mt-5">
                <SocialList />
            </div>
        </div>
    </footer>
</template>
<script>

import SocialList from "@/components/SocialList";
import BookCarousel from "@/components/BookCarousel";

export default {
    name: 'Footer',
    props: {
        books: Array,
    },
    components: {
        SocialList,
        BookCarousel
    }
}
</script>

<style scoped>
footer {
    background: var(--var-bg-color);
    padding: 3em;
    min-height: 5em;
    width: 100%;
    align-items: center;
}
</style>
