import ContentfulApi from "@/contentful/Api";

export default class ContentfulPageContent extends ContentfulApi {
    static async getFeatured() {
        const query = `
            {
                collection:eventCollection(limit: 5, where: {featured: true, }, order: [time_ASC]) {
                    items {
                        title,
                        time,
                        description {
                            json
                        },
                        place,
                        url
                    }
                }
            }`;

        return this.getCollection(query);
    }
}