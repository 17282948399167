import ContentfulApi from "@/contentful/Api";

export default class ContentfulPageContent extends ContentfulApi {
    static async getFeatured() {
        const query = `
            {
                collection:bookCollection(limit: 1, where: {featured: true}) {
                    items {
                        sys {
                            id
                        }
                    title,
                    content {
                        json  
                    },
                    cover {
                        title,
                        url
                    },
                    publisher (preview: true) {
                        title,
                        url,
                        logo {
                            title,
                            url
                        }
                    },
                    purchases:purchasesCollection {
                        items {
                            label,
                            url
                        }
                    },
                    testimonials:linkedFrom {
                        collection:testimonialCollection {
                            items {
                                author,
                                content {
                                    json
                                }
                            }
                        }
                    }
                }
            }
        }`;

        return this.getSingle(query);
    }

    static async getBySlug(slug) {
        const query = `
            {
                collection:bookCollection(limit: 1, where: {slug: "${slug}"}) {
                    items {
                        sys {
                            id
                        }
                    title,
                    content {
                        json  
                    },
                    cover {
                        title,
                        url
                    },
                    publisher (preview: true) {
                        title,
                        url,
                        logo {
                            title,
                            url
                        }
                    },
                    purchases:purchasesCollection {
                        items {
                            label,
                            url
                        }
                    },
                    testimonials:linkedFrom {
                        collection:testimonialCollection {
                            items {
                                author,
                                content {
                                    json
                                }
                            }
                        }
                    }                    
                }
            }
        }`;

        return this.getSingle(query);
    }
}