<template>
    <div v-if="book" class="row">
        <div class="order-2 order-lg-1 col-10 offset-1 col-lg-7 offset-lg-0">
            <h1 class="text-center text-lg-start mt-3 mt-lg-0">{{ book.title }}</h1>

            <div id="fds" v-if="book.testimonials.collection.items.length">
                <figure
                    class="fds-item testimonial"
                    v-for="testimonial in book.testimonials.collection.items"
                    :key="testimonial.author"
                >
                    <blockquote v-html="getContent(testimonial.content.json)"></blockquote>
                    <figcaption>— {{ testimonial.author }}</figcaption>
                </figure>
            </div>

            <div class="" v-if="book.purchases.items.length">
                <a class="btn btn-outline-light me-2"
                   v-for="purchase in book.purchases.items"
                   :key="purchase.label"
                   :href="purchase.url"
                   target="_blank"
                >
                    {{ purchase.label }}
                </a>
            </div>
        </div>

        <div class="order-1 order-lg-2 order-sm-1 col-10 offset-1 col-lg-5 offset-lg-0 text-center">
            <img class="book-cover" :src="book.cover.url" :alt="book.cover.title" />
        </div>
    </div>
</template>

<script>

import { getContent } from '../utils/utils'

export default {
    name: 'BookHeader',
    props: {
        book: Object,
    },
    methods: {
        getContent(content) {
            return getContent(content);
        },
    },
}

</script>

<style scoped>

#fds {
    position: relative;
    overflow-y: hidden;
    height: 250px;
}
.fds-item {
    position: absolute;
    margin: 0;
    -webkit-transform: translate3d(0, 0, 0, 0);
    opacity: 0;
}
.fds-item:nth-child(1) {
    animation: rotateBanner 40s infinite;
}
.fds-item:nth-child(2) {
    animation: rotateBanner 40s 10s infinite;
}
.fds-item:nth-child(3) {
    animation: rotateBanner 40s 20s infinite;
}
.fds-item:nth-child(4) {
    animation: rotateBanner 40s 30s infinite;
}

@keyframes rotateBanner {
    0% {
        opacity: 0;
        transform: translateY(-3ex);
    }
    10% {
        opacity: 1;
        transform: translateY(0);
    }
    25% {
        opacity: 1;
        transform: translateY(0);
    }
    35% {
        opacity: 0;
        transform: translateY(3ex);
    }
    100% {
        opacity: 0;
    }
}
</style>
