export default class ContentfulApi {
    static async callContentful(query) {
        const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE}/environments/master`;

        const fetchOptions = {
            method: "POST",
            headers: {
                Authorization: "Bearer " + process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ query }),
        };

        try {
            return await fetch(fetchUrl, fetchOptions).then((response) =>
                response.json(),
            );
        } catch (error) {
            throw new Error("Could not fetch data from Contentful!");
        }
    }

    static async getSingle(query) {
        const response = await this.callContentful(query);

        const list = response.data.collection.items
            ? response.data.collection.items
            : [];

        return list.pop();
    }

    static async getCollection(query) {
        const response = await this.callContentful(query);

        return response.data.collection.items
            ? response.data.collection.items
            : [];
    }
}