<template>
    <div class="wrapper">
        <section id="hero">
            <BookHeader :book="featuredBook" />
        </section>

        <section id="featured-event" v-if="featuredEvents.length">
            <article
                class="col-8 offset-2 alert alert-event mt-5"
                v-for="featuredEvent in featuredEvents"
                :key="featuredEvent.slug">
                <h3>{{ featuredEvent.title }}</h3>

                <div class="row">
                    <div class="col-10 offset-1 col-lg-4 offset-lg-0">
                        <icon icon="fas fa-clock" /> {{ this.formatDate(featuredEvent.time) }}
                    </div>
                    <div class="col-10 offset-1 col-lg-8 offset-lg-0">
                        <icon icon="fas fa-map" /> {{ featuredEvent.place }}
                    </div>
                </div>

                <p v-if="featuredEvent.url" class="mt-3">
                    Renginio nuoroda: <a :href="featuredEvent.url" class="alert-link" target="_blank">{{ featuredEvent.url }}</a>
                </p>
            </article>
        </section>

        <About />
    </div>
</template>

<script>

import Book from '../contentful/Book/Book'
import Event from '../contentful/Event/Event'
import BookHeader from "@/components/BookHeader";
import About from "@/components/About";
import moment from "moment/moment";

export default {
    name: 'Homepage',
    components: {
        BookHeader,
        About
    },
    data() {
        return {
            featuredBook: null,
            featuredEvents: []
        }
    },
    methods: {
        async getData() {
            this.featuredBook = await Book.getFeatured();
            this.featuredEvents = await Event.getFeatured();
        },
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD, HH:mm');
        }
    },
    mounted() {
        this.getData();
    },
}
</script>

<style scoped>
.alert-event {
    background: var(--var-bg-color);
}
</style>
