import ContentfulApi from "@/contentful/Api";

export default class ContentfulPageContent extends ContentfulApi {
    static async getList() {
        const query = `
            {
                collection:bookCollection {
                    items {
                        sys {
                            id
                        }
                    title,
                    slug,
                    cover {
                        title,
                        url
                    }
                }
            }
        }`;

        return this.getCollection(query);
    }
}