<template>
    <div class="wrapper">
        <section id="hero">
            <BookHeader :book="book" />
        </section>

        <section class="content" v-if="content">
            <article class="row justify-content-center">
                <div class="col-10 col-md-5">
                    <div class="content" v-html="content"></div>
                </div>
            </article>
        </section>
    </div>
</template>

<script>

import Book from '../contentful/Book/Book'
import BookHeader from "@/components/BookHeader";
import { getContent } from '../utils/utils'

export default {
    name: 'Book',
    components: {
        BookHeader
    },
    data() {
        return {
            book: null,
            content: null
        }
    },
    methods: {
        async getBySlug() {
            await Book.getBySlug(this.$route.params?.slug)
                .then((book) => {
                    if (book === undefined) {
                        return this.$router.push('/404');
                    }

                    this.book = book;
                    this.content = getContent(book.content.json);
                });
        },
    },
    mounted() {
        this.getBySlug();
    },
}
</script>

<style scoped>
.content {
    margin: 2.5rem 0;
}
</style>
