<template>
    <ul class="social-icons list-inline text-center me-md-3">
        <li v-for="social in socials"
            :key="social.type"
            class="list-inline-item me-3"
        >
            <a :href="social.url" target="_blank"><icon :icon="social.icon" /> <span class="d-none d-md-inline-block">{{ social.handle }}</span></a>
        </li>
    </ul>
</template>

<script>

import socialItems from "./../data/social.json";

export default {
    name: 'SocialList',
    data() {
        return {
            socials: socialItems
        }
    }
}
</script>

<style scoped>
    .social-icons {
        display: inline-block;
    }
</style>
