import ContentfulApi from "@/contentful/Api";

export default class ContentfulPageContent extends ContentfulApi {
    static async getList() {
        const query = `
            {
                collection:eventCollection(order: [time_DESC]) {
                    items {
                    title,
                    time,
                    description {
                        json
                    },
                    place,
                    url
                }
            }
        }`;

        return this.getCollection(query);
    }
}