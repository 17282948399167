<template>
    <div class="wrapper">
        <section class="content" v-if="content">
            <article class="row justify-content-center">
                <div class="col-10 col-md-8">
                    <h1>{{ item.title }}</h1>
                    <div class="content" v-html="content"></div>
                </div>

                <div class="col-10 col-md-8">
                    <router-link to="/tekstai" class="btn btn-text mt-5">Atgal</router-link>
                </div>
            </article>
        </section>
    </div>
</template>

<script>

import Page from '../contentful/Page/Page'
import { getContent } from '../utils/utils'

export default {
    name: 'Text',
    data() {
        return {
            item: null,
            content: null,
        }
    },
    methods: {
        async getBySlug() {
            console.log(this.$route.params?.slug);

            await Page.getBySlug(this.$route.params?.slug)
                .then((item) => {
                    if (item === undefined) {
                        return this.$router.push('/404');
                    }

                    console.log(item);

                    this.item = item;
                    this.content = getContent(item.body.json);
                }).catch(() => {
                    //this.$router.push('/404');
                });
        },
    },
    mounted() {
        this.getBySlug();
    },
}
</script>

<style scoped>
.content {
    margin: 2.5rem 0;
    line-height: 2rem;
    font-size: 1.25rem;
    text-align: left;
    color: #030303;
    font-weight: 700;
}

.btn-text {
    background: var(--var-bg-color);

    &:hover {
        background-color: var(--var-bg-darken-color);
        color: var(--var-primary-color);
    }
}
</style>
