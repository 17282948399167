<template>
    <div class="wrapper">
        <section id="hero">
            <h1>Tekstai</h1>
        </section>

        <section class="content" v-if="items.length">
            <article
                class="col-8 offset-2 mt-5"
                v-for="item in items"
                :key="item.slug">

                <h2>
                    <router-link :to="'/tekstas/' + item.slug">{{ item.title }}</router-link>
                </h2>

                <p v-if="item.intro.length">{{ item.intro }}</p>

                <router-link :to="'/tekstas/' + item.slug" class="btn btn-text">Daugiau</router-link>
            </article>
        </section>
    </div>
</template>

<script>

import Page from '../contentful/Page/Page'
import * as utils from '../utils/utils'

export default {
    name: 'Texts',
    components: {
    },
    data() {
        return {
            items: []
        }
    },
    methods: {
        async getList() {
            this.items = await Page.getList();
        },
        getContent(content) {
            return utils.getContent(content);
        },
        formatDate(date) {
            return utils.formatDate(date);
        }
    },
    mounted() {
        this.getList();
    },
}
</script>

<style scoped>
#hero {
    min-height: 50vh;
}

.content {
    margin: 2.5rem 0;
}

h2 {
    margin: 1rem 0;

    a {
        color: var(--var-bg-color);
        text-decoration: underline;
        font-weight: 900;

        &:hover {
            text-decoration: none;
        }
    }
}

.btn-text {
    background: var(--var-bg-color);

    &:hover {
        background-color: var(--var-bg-darken-color);
        color: var(--var-primary-color);
    }
}
</style>
