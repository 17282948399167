<template>
    <div class="wrapper">
        <section id="hero">
            <h1>Renginiai</h1>
        </section>

        <section class="content" v-if="events.length">
            <article
                class="col-8 offset-2 alert alert-event mt-5"
                v-for="event in events"
                :key="event.slug">
                <h3>{{ event.title }}</h3>

                <div class="row">
                    <div class="col-10 offset-1 col-lg-4 offset-lg-0">
                        <icon icon="fas fa-clock" /> <span class="ms-1">{{ this.formatDate(event.time) }}</span>
                    </div>
                    <div class="col-10 offset-1 col-lg-8 offset-lg-0">
                        <icon icon="fas fa-map" /> <span class="ms-1">{{ event.place }}</span>
                    </div>
                </div>

                <p v-if="event.url" class="mt-3">
                    Renginio nuoroda: <a :href="event.url" class="alert-link" target="_blank">{{ event.url }}</a>
                </p>

                <div class="content" v-if="event.description" v-html="getContent(event.description.json)"></div>
            </article>
        </section>
    </div>
</template>

<script>

import EventCollection from '../contentful/Event/EventCollection'
import * as utils from '../utils/utils'

export default {
    name: 'Events',
    components: {
    },
    data() {
        return {
            events: []
        }
    },
    methods: {
        async getList() {
            this.events = await EventCollection.getList();
        },
        getContent(content) {
            return utils.getContent(content);
        },
        formatDate(date) {
            return utils.formatDate(date);
        }
    },
    mounted() {
        this.getList();
    },
}
</script>

<style scoped>
#hero {
    min-height: 50vh;
}

.content {
    margin: 2.5rem 0;
}

.alert-event {
    background: var(--var-bg-color);
}
</style>
